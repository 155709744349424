export const blogData = [
    {
        title: "Building a website with Tailwind CSS and React",
        date: "February 22nd, 2022",
        text: "Lorem ipsum dolor sit amet dolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit amet dolor sit ametdolor sit amet dolor sit amet dolor sit dolor sit amet",
        tags: ['Web Development', 'Tutorial', 'React JS'],
        id: 0
    },
    {
        title: "How I hosted a hackathon competition for high school students",
        date: "February 22nd, 2022",
        text: "Lorem ipsum dolor sit amet dolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit amet dolor sit ametdolor sit amet dolor sit amet dolor sit dolor sit amet",
        tags: ['Web Development', 'Tutorial', 'React JS'],
        id: 1
    },
    {
        title: "My thoughts on the future of AI, and what we can do to fix it",
        date: "February 22nd, 2022",
        text: "Lorem ipsum dolor sit amet dolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit amet dolor sit ametdolor sit amet dolor sit amet dolor sit dolor sit amet",
        tags: ['Web Development', 'Tutorial', 'React JS'],
        id: 2
    },
    {
        title: "My progression as a web developer",
        date: "February 22nd, 2022",
        text: "Lorem ipsum dolor sit amet dolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit ametdolor sit amet dolor sit ametdolor sit amet dolor sit amet dolor sit dolor sit amet",
        tags: ['Web Development', 'Tutorial', 'React JS'],
        id: 3
    },
]