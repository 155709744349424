export const mlprojects = [
  {
    title: "HPV Vaccine Hesitancy",
    href: "https://github.com/s102737/hpv-vaccine-hesitancy",
    description:
      "NLP Techniques employed to analyze sentiment of the HPV vaccine among subreddits.",
    tags: ["Python", "NLP", "Sentiment Analysis"],
  },
  {
    title: "GPT2 Quote Generation",
    href: "https://github.com/farbs03/quotegeneration",
    description:
      "Quotes are generated using the GPT2 language model and webscraped text data.",
    tags: ["Python", "Tensorflow", "RNNs"],
  },
  {
    title: "Image to Text",
    href: "https://github.com/farbs03/googleCloudBackend",
    description:
      "Using Google Cloud's Vision AI, this API takes an image and returns any text it detects.",
    tags: ["Node JS", "Google Cloud", "API"],
  },
  {
    title: "MNIST Handwriting Recognition",
    href: "https://github.com/farbs03/mnist_classification",
    description:
      "Using Tensorflow and Keras, I trained a model to recognize handwritten digits with the MNIST data.",
    tags: ["Python", "Tensorflow", "Keras"],
  },
  /*
    {
        title: "Linear Regression",
        href: "https://github.com/",
        description: "Basic linear regression implementation in vanilla Python, inspired by Stanford's Coursera ML Course.",
        tags: [
            'Python', 'ML'
        ]
    }
    */
];